import { Container, Row, Col, Button } from "react-bootstrap";
import { Select, Form, Input, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDashboardClientList } from "../hooks";
import { LoanProposeRepaymentPlan } from "./LoanProposeRepaymentPlan";
import { LoanRequestValidation } from "./LoanRequestValidation";

import { validateLoanRequest, newLoanRequest } from "../store/loans";
import { showAlertAction } from "../store/alert";
import { getAllCreateCollateralsAction } from "../store/clients";

const initialLoanPaymentData = {
  net_pay: undefined,
  insurance_fee: undefined,
  processing_fee: undefined,
};

const LoanRequestForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    listData: clientsOption,
    handleGetGuarantors,
    guarantorList: guarantorsOption,
  } = useDashboardClientList(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedGuarantor, setSelectedGuarantor] = useState(null);
  const [clientCollaterals, setClientCollaterals] = useState([]);
  const [formState, setFormState] = useState({});
  const [hasVerifiedGuarantor, setHasVerifiedGuarantor] = useState(false);
  const [hasVerifiedClient, setHasVerifiedClient] = useState(false);
  const [netPay, setNetPay] = useState()
  const [loanPaymentData, setLoanPaymentData] = useState(
    initialLoanPaymentData
  );
  const [eligibilityData, setEligibilityData] = useState({
    isEligible: false,
    showEligibilityCard: false,
  });
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const { client, interest_rate, loan_duration, loan_amount, interest_type, collateral_id } =
    formState;
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));

  const handleAutoFill = (isClient) => {
    if (isClient && selectedClient) {
      form.setFieldsValue({
        verified_name: `${selectedClient?.surname} ${selectedClient?.firstname}`,
        marital_status: selectedClient?.marital_status,
        house_address: selectedClient?.house_address,
        phone_number1: selectedClient?.phone_number1,
        phone_number2: selectedClient?.phone_number2,
        office_address: selectedClient?.office_address,
      });
      return;
    }

    if (!isClient && selectedGuarantor) {
      form.setFieldsValue({
        guarantor_house_address: selectedGuarantor?.house_address,
        guarantor_office_address: selectedGuarantor?.office_address,
      });
    }
  };

  const handleClientSelect = async (val) => {
    setSelectedClient(() => clientsOption?.find((inst) => inst?.id === val));

    dispatch(
      getAllCreateCollateralsAction({ querySet: `client_id=${val}` })
    ).then((res) => {
      setClientCollaterals(res);
    });
  };

  const handleGuarantorSelect = async (val) => {
    setSelectedGuarantor(() =>
      guarantorsOption?.find((inst) => inst?.id === val)
    );
  };

  const checkEligibility = async (data) => {
    setValidating(true);
    const res = await dispatch(validateLoanRequest(data));

    setEligibilityData((current) => ({
      ...current,
      ...res,
      showEligibilityCard: true,
      isEligible: !Object.keys(res).some((key) => res[key]?.status === false),
    }));
    setValidating(false);
  };

  const handleSubmit = async (values) => {
    if (loading || validating || !hasVerifiedClient || !hasVerifiedGuarantor) {
      dispatch(
        showAlertAction({
          message:
            "Please click to verify clients and guarantors before ytou proceed.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }
    if (Number(values?.admin_fee) < 100) {
      dispatch(
        showAlertAction({
          message: "Admin fee cannot be less than ₦100",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }
    if (!eligibilityData?.isEligible && !["DR", "HOP", "HOI", "UM"].includes(user?.position)) {
      // Submit
      dispatch(
        showAlertAction({
          message:
            "This loan request fails some eligibility criteria, you do not have enough permission to proceed.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }
    setLoading(true);
    await dispatch(
      newLoanRequest({
        ...values,
        ...loanPaymentData,
        client_id: values?.client,
        guarantor_id: values?.guarantor,
      })
    );

    form.resetFields();
    setEligibilityData({
      isEligible: false,
      showEligibilityCard: false,
    });
    setLoanPaymentData(initialLoanPaymentData);
    setLoading(false);
  };

  const updateFormState = () => setFormState(form.getFieldsValue());

  const getPaymentData = () => {
    const interest = parseInt(
      Number(loan_amount) * (Number(interest_rate) / 100)
    );
    const net_pay = netPay;
    const insurance_fee = parseInt(Number(loan_amount) * 0.01);
    const processing_fee = parseInt(Number(loan_amount) * 0.015);

    return {
      interest,
      net_pay,
      insurance_fee,
      processing_fee,
    };
  };

  useEffect(() => {
    if (
      interest_rate &&
      loan_duration &&
      loan_amount &&
      Number(loan_amount) > 0
    ) {
      setLoanPaymentData((current) => ({
        ...current,
        ...getPaymentData(),
      }));
    }
  }, [formState, netPay]);

  useEffect(() => {
    if (selectedClient && !guarantorsOption?.length) {
      handleGetGuarantors();
    }
    
  }, [selectedClient]);

  useEffect(() => {
    handleAutoFill(true);
  }, [selectedClient]);

  useEffect(() => {
    handleAutoFill(false);
  }, [selectedGuarantor]);

  /**
   * Loan Collection conditions
   * 1. Nin has no outstanding loan
   * 2. Guarantor has no outstanding loan
   * 3. Client has guaranteed an unfinished loan
   */

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Request new loan</h3>

      <Form
        form={form}
        onFinish={(values) => handleSubmit(values)}
        onFieldsChange={updateFormState}
      >
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Client"
              name="client"
              rules={[
                {
                  required: true,
                  message:
                    "Please select a client, or create a new client first",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select Client"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onSelect={handleClientSelect}
              >
                {clientsOption?.map(({ id, firstname, surname }) => (
                  <Select.Option value={id} key={id}>
                    {`${surname} ${firstname}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Guarantor"
              name="guarantor"
              rules={[
                {
                  required: true,
                  message: "Please select a guarantor or create one",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select Guarantor"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                disabled={!client}
                onSelect={handleGuarantorSelect}
              >
                {guarantorsOption?.map(({ id, firstname, surname }) => (
                  <Select.Option value={id} key={id}>
                    {`${surname} ${firstname}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Select Collateral"
              name="collateral_id"
              rules={[
                {
                  required: true,
                  message: "Please select collateral",
                },
              ]}
            >
              <Select
                placeholder="Collaterals"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {clientCollaterals?.map((instance, index) => (
                  <Select.Option value={instance?.id} key={index}>
                    {instance?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              label="Loan Amount"
              name="loan_amount"
              rules={[
                {
                  required: true,
                  min: 0,
                  message: "Please provide loan amount",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Loan Amount"
                prefix="₦"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Loan Duration (Months)"
              name="loan_duration"
              rules={[
                {
                  required: true,
                  message: "Please provide a loan duration",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Loan duration"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Interest Type"
              name="interest_type"
              rules={[
                {
                  required: true,
                  message: "Please select an interest type",
                },
              ]}
            >
              <Select
                placeholder="Select interest type"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
              >
                <Select.Option value="flat" key={1}>
                  Flat rate
                </Select.Option>
                <Select.Option value="reducing" key={2}>
                  Reducing balance
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Interest Rate"
              name="interest_rate"
              rules={[
                {
                  required: true,
                  message: "Please select an interest rate",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Interest rate"
              />
            </Form.Item>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md="4">
            <Form.Item
              label="Verified name"
              name="verified_name"
              rules={[
                {
                  required: true,
                  message: "Please provide a verified Name from Id card",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Verified Name"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Marital status"
              name="marital_status"
              rules={[
                {
                  required: true,
                  message: "Please provide current marital status",
                },
              ]}
            >
              <Select
                placeholder="Current Marital Status"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
              >
                <Select.Option value="married" key="married">
                  Married
                </Select.Option>
                <Select.Option value="single" key="single">
                  Single
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="House address"
              name="house_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current homes address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Current House address"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <Form.Item
              label="Office address"
              name="office_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current office address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Current Office address"
              />
            </Form.Item>
          </Col>
          <Col md="4">
            <Form.Item
              label="Phone number 1"
              name="phone_number1"
              rules={[
                {
                  required: true,
                  message: "Please provide current phone number",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Current Phone Number 1"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item label="Phone Number 2" name="phone_number2">
              <Input
                className="form-control"
                type="number"
                placeholder="Current Phone number 2"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Guarantor house address"
              name="guarantor_house_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current guarantor house address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Guarantor house address"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Guarantor office address"
              name="guarantor_office_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current guarantor office address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Guarantor office address"
              />
            </Form.Item>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md="4">
            <Form.Item
              label="Affidavit fee"
              name="affidavit_fee"
              rules={[
                {
                  required: true,
                  message: "Please provide a affidavit fee",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Affidavit Fee"
                prefix="₦"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Form fee"
              name="form_fee"
              rules={[
                {
                  required: true,
                  message: "Please provide a form fee",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Form Fee"
                prefix="₦"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Admin fee"
              name="admin_fee"
              rules={[
                {
                  required: true,
                  message: "Please provide admin fee",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Admin Fee"
                prefix="₦"
              />
            </Form.Item>
          </Col>
        </Row>

        <hr />
        <small>Auto generated fields</small>
        <hr />

        <Row>
          <Col className="mb-2" md="4">
            <span>Loan Amount</span>
            <div className="form-control ant-input-disabled">
              ₦ {loan_amount ? Number(loan_amount)?.toLocaleString() : ""}
            </div>
          </Col>
          <Col className="" md="4">
            <span>Net Pay</span>
            <Form.Item style={{ display: "none" }}>
              <Input
                value={loanPaymentData.net_pay}
                className="form-control"
                type="number"
                disabled
              />
            </Form.Item>
            <div className="form-control ant-input-disabled">
              ₦ {loanPaymentData.net_pay?.toLocaleString()}
            </div>
          </Col>
          <Col className="mb-2" md="4">
            <span>Insurance Fee</span>
            <Form.Item style={{ display: "none" }}>
              <Input
                value={loanPaymentData.insurance_fee}
                className="form-control"
                type="number"
                disabled
              />
            </Form.Item>
            <div className="form-control ant-input-disabled">
              ₦ {loanPaymentData.insurance_fee?.toLocaleString()}
            </div>
          </Col>
          <Col className="mb-2" md="4">
            <span>Processings Fee</span>
            <Form.Item style={{ display: "none" }}>
              <Input
                value={loanPaymentData.processing_fee}
                className="form-control"
                type="number"
                disabled
              />
            </Form.Item>
            <div className="form-control ant-input-disabled">
              ₦ {loanPaymentData.processing_fee?.toLocaleString()}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <LoanProposeRepaymentPlan
              interestRate={interest_rate}
              duration={loan_duration}
              amount={loan_amount}
              interestType={interest_type}
              onChangeNetPay={setNetPay}
            />

            <LoanRequestValidation
              onValidate={() => {
                if (formState?.guarantor && formState?.client) {
                  checkEligibility({
                    guarantor: formState?.guarantor,
                    client: formState?.client,
                    collateral_id
                  });
                }
              }}
              eligibilityData={eligibilityData}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Checkbox
              onChange={(e) => setHasVerifiedGuarantor(e.target.checked)}
            >
              I have verified client
            </Checkbox>

            <Checkbox onChange={(e) => setHasVerifiedClient(e.target.checked)}>
              I have verified guarantor
            </Checkbox>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              type="submit"
              disabled={validating || loading}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { LoanRequestForm };
