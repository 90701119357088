import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";

import { deleteOrgSectorAction, getAllBranches } from "../store/org";

import { ModifiedAntTable, LoadingSpinner } from ".";
import { current } from "@reduxjs/toolkit";

const BranchesList = () => {
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const dispatch = useDispatch();

  const {
    users: { user },
    org: { loading, branches },
  } = useSelector((state) => ({
    org: state.org,
    users: state.users,
  }));

  const handleDeleteOrg = async (id) => {
    if (
      window.confirm(
        `Are you sure you want to delete this branch Note: This would delete everything within this organization.`
      )
    ) {
      dispatch(deleteOrgSectorAction({ type: "branches", id })).then(() => {
        setTriggerRefresh(current => !current)
      });
    }
  };

  const getOrgColumn = useCallback(() => {
    const columns = [
      {
        title: "S/N",
        dataIndex: "id",
        render: (text, record, index) =>
          ((Number(page) || 1) - 1) * 50 + (index + 1),
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/branches/${record.id}`}>
            {text} <i class="fa fa-link" aria-hidden="true"></i>
          </Link>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Edit",
        render: (text, record) => (
          <Link to={`/branches/${record.id}/edit`}>
            Edit <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
        ),
      },
    ];

    if (["DR", "HOP"].includes(user?.position)) {
      columns.push({
        title: "",
        dataIndex: "id",
        render: (text, record) => (
          <span role="button" onClick={() => handleDeleteOrg(record?.id)}>
            <i className="fas fa-trash"></i>
          </span>
        ),
        summary: "-",
      });
    }

    return columns;
  }, []);

  useEffect(() => {
    dispatch(getAllBranches())
  }, [triggerRefresh])

  return (
    <Container fluid>
      <h3 className="page-title mb-4">List of branches in the organization</h3>

      <LoadingSpinner loading={loading} />

      <ModifiedAntTable
        rowKey="id"
        scroll={{ x: "max-content" }}
        columns={getOrgColumn()}
        dataSource={branches}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </Container>
  );
};

export { BranchesList };
